import { useUserStore } from '~/stores/user'

export default defineNuxtRouteMiddleware(to => {
  const userStore = useUserStore()
  if (!userStore.isLogin) {
    ElNotification({
      title: '提示',
      message: '尚未登入，請登入之後訪問。',
      type: 'warning'
    })
    return navigateTo({ path: '/member/login', query: { redirect: to.path } })
  }
})
